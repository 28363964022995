import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SiteService } from 'src/app/services/site.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-homeone-banner',
    templateUrl: './homeone-banner.component.html',
    styleUrls: ['./homeone-banner.component.scss']
})
export class HomeoneBannerComponent implements OnInit {
	data: any;
	server = environment.base_url
    constructor(private siteService: SiteService) { }

    ngOnInit(): void {
		this.siteService.get().subscribe(
			(res: any) => this.data = res.data
		)
    }

    trustedBySlides: OwlOptions = {
		nav: false,
		loop: true,
		margin: 25,
		dots: false,
		autoplay: true,
		autoplayHoverPause: true,
		navText: [
			"<i class='ri-arrow-left-s-line'></i>",
			"<i class='ri-arrow-right-s-line'></i>",
		],
		responsive: {
			0: {
				items: 2
			},
			576: {
				items: 4
			},
			768: {
				items: 4
			},
			992: {
				items: 4
			},
			1200: {
				items: 4
			}
		}
    }

}
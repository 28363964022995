import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SiteService {


  constructor(private http: HttpClient) { }

  get = () => this.http.get(`${environment.base_url}/${environment.api_prefix}/sites`);
  getTerms = () => this.http.get(`${environment.base_url}/${environment.api_prefix}/terms`);
}

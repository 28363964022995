<div
  lang="ar"
  dir="rtl"
  class="bg-F7F7FF droid-arabic-kufi features-area pos3 ptb-100"
>
  <div class="container">
    <div class="align-items-center row">
      <div
        class="col-lg-6 col-md-12"
        style="
          visibility: visible;
          animation-duration: 1s;
          animation-delay: 0.1s;
          animation-name: slideInRight;
          margin-bottom: 20px;
        "
      >
        <!-- [@foobar2]="state" -->
        <div class="features-content pos4">
          <ul class="features-list">
            <h3 class="pos">
              {{ data.featureSectionTitle }}
            </h3>
            <br />
            <li class="anim" *ngFor="let feature of data.features">
              <div lang="ar" dir="rtl" class="icon pos5">
                <img
                  class="pos8"
                  [src]="server + feature.icon.public_url"
                  width="40"
                  height="40"
                />
              </div>

              <h5 class="text">
                {{ feature.title }}
              </h5>

              <p class="text1">
                {{ feature.description }}
              </p>
            </li>
          </ul>
        </div>
      </div>
      <!-- [@foobar]="state" -->
      <div
        lang="ar"
        dir="rtl"
        class="col-lg-6 col-md-12"
        style="
          visibility: visible;
          animation-duration: 1s;
          animation-delay: 0.1s;
          animation-name: slideInLeft;
        "
      ></div>
    </div>
  </div>
  <div class="bg-shape1">
    <img src="assets/img/shape/bg-shape1.png" alt="bg-shape" />
  </div>
  <div class="text-center features-image">
    <!--                     <img src="assets/img/service-bg2.svg"  alt="app-img" data-aos="fade-up">
-->
    <img
      class="m-1 img"
      [src]="server + image.public_url"
      *ngFor="let image of data.images"
      width="240"
      height="530"
    />
  </div>
</div>

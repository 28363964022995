import { Component, OnInit } from '@angular/core';
import { SiteService } from 'src/app/services/site.service';

@Component({
  selector: 'app-method',
  templateUrl: './method.component.html',
  styleUrls: ['./method.component.scss']
})
export class MethodComponent implements OnInit {
  data: any;

  constructor(private service: SiteService) { }

  ngOnInit(): void {
    this.service.get().subscribe(
      (res: any) => {
        this.data = res.data
      }
    )
  }

}

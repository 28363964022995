<!-- <div lang="ar" dir="rtl" class="bg-F7F7FF droid-arabic-kufi features-area pos3 ptb-100">
    <div class="container">
        <div class="align-items-center bg row">
            <div [@foobar2]="state" class="col-lg-6 col-md-12">
                <div class="features-content pos4">
                    <ul class="features-list">
                        <h5 class="pos">خدمة توصيل بلا تواصل

                        </h5>

                       
<div  class="mt-5 col-lg-8 col-md-12">
    <div class="colacc droid-arabic-kufi row" lang="ar" dir="rtl">
        <div class="col">
            <br>
            <br>
            <br>
           
        </div>

    </div>
</div>
                    </ul>

                </div>
            </div>
            <div [@foobar]="state" lang="ar" dir="rtl" class="col-lg-6 col-md-12">
                <div class="text-center features-image">
                                     <img src="assets/img/service-bg2.svg"  alt="app-img" data-aos="fade-up">
  <img src="assets/img/about.png">
                </div>
            </div>
        </div>
    </div>
    <div class="bg-shape1"><img src="assets/img/shape/bg-shape1.png" alt="bg-shape"></div>
</div>
 -->

<div class="container">
  <div class="row">
    <div class="col-sm-6 anim">
      <div class="text-center features-image">
        <img src="assets/img/cuate.svg" alt="app-img" />
      </div>
    </div>
    <div class="col-sm-6 bg1">
      <h3 class="droid-arabic-kufi pos" lang="ar" dir="rtl">
        خدمة توصيل بلا تواصل
      </h3>
      <div class="droid-arabic-kufi tabs" lang="ar" dir="rtl">
        <div class="tab" *ngFor="let service of data.services">
          <input type="checkbox" id="chck{{service.id}}" />
          <label class="tab-label" for="chck{{service.id}}">
            <p class="anim lead">
              <img src="assets/img/check-box_4.svg" width="12" height="12" />
              &nbsp; {{ service.title }}
            </p>
          </label>
          <div class="tab-content">
            {{ service.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

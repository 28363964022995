<!-- <div class="acc">


<div class="mt-5 accordion delivery-ser-box" id="accordionExample2">

	<div class="card del-card" type="button" data-toggle="collapse" data-target="#collapse12" aria-expanded="true" aria-controls="collapse1">
							<div class="card-header" id="heading1 wow zoomIn" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0" style="visibility: visible; animation-duration: 1s; animation-delay: 0.1s; animation-name: zoomIn;">
								
							<button class="btn-block text-right btn btn-link">
							 <span><i class="fa-chevron-down fas"></i></span> كيف احمل تطبيق كلين؟
							</button>
						  
							</div>

							<div id="collapse12" class="collapse fade show" aria-labelledby="heading1" data-parent="#accordionExample2">
								<div class="card-body delavery-details">
									<p>من خلال أبل ستور لأجهزة الآيفون وقوقل بلاي لأجهزة الاندرويد </p>
								</div>
							</div>
						</div>
</div>
</div> -->
<div class="droid-arabic-kufi pos">
  <div class="container">
    <div class="page-title-content">
      <h2 class="col2 wow zoomIn">الأسئلة الشائعة</h2>
    </div>
  </div>
</div>

<div class="container">
    <div class="bg faq row">
      <!-- Start Section -->
      <div class="bg2 col">
        <div>
          <div class="bg3 wow zoomIn">
            <img
              class=""
              src="assets/images/washing-machine.png"
              width="50"
              height="50"
            />
          </div>
          <a
            (click)="onSelect()"
            type="submit"
            class="{{ class1 }} pos2 wow zoomIn"
            ><span>ابدأ تجربة النظافة المثالية</span></a
          >
        </div>
      </div>
  
      <!-- Services and Prices Section -->
      <div class="bg2 col">
        <div>
          <div class="wow zoomIn">
            <img
              class=""
              src="assets/images/t-shirt.png"
              width="50"
              height="50"
            />
          </div>
          <a
            (click)="onSelect2()"
            type="submit"
            class="{{ class2 }} pos2 wow zoomIn"
            ><span>تعرف على خدماتنا وأسعارنا</span></a
          >
        </div>
      </div>
  
      <!-- Other Section -->
      <div class="bg2 col">
        <div>
          <div class="wow zoomIn">
            <img
              class=""
              src="assets/images/polo-shirt.png"
              width="50"
              height="50"
            />
          </div>
          <a
            (click)="onSelect3()"
            type="submit"
            class="{{ class3 }} pos2 wow zoomIn"
            ><span>اكتشف المزيد من خياراتنا</span></a
          >
        </div>
      </div>
    </div>
  </div>
  
<div class="faq-area ptb-100">
  <div lang="ar" dir="rtl" class="container">
    <div class="row">
      <!--     <div class="col-lg-4 col-md-12">
                <div class="faq-sidebar">
                    <ul  >
                        <li><a (click)="onSelect()" type="submit" class={{class1}}><span>البداية</span></a></li>
                        <li><a (click)="onSelect2()" type="submit" class={{class2}} ><span>الخدمات والاسعار</span></a></li>
                        <li><a (click)="onSelect3()" type="submit" class={{class3}}  ><span>اخرى</span></a></li>
           
                    </ul>
                </div>
            </div> -->
      <div *ngIf="show" class="mt-5 col-lg-8 col-md-12">
        <div class="colacc droid-arabic-kufi row" lang="ar" dir="rtl">
          <div class="col">
            <br />
            <br />
            <br />
            <div class="tabs">
              <div class="tab" *ngFor="let faq of data.faqs">
                <input type="checkbox" id="chck{{faq.id}}" />
                <label class="tab-label" for="chck{{faq.id}}">
                  <p class="anim">
                    {{ faq.question }}
                  </p>
                </label>
                <div class="tab-content">
                  {{ faq.response }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="show2" class="mt-5 col-lg-8 col-md-12">
        <div class="colacc container droid-arabic-kufi">
          <div class="row" lang="ar" dir="rtl">
            <div class="col">
              <br />
              <br />
              <br />
              <div class="tabs">
                <div class="tab">
                  <input type="checkbox" id="chck1" />
                  <label class="tab-label" for="chck1">
                    <p class="anim">ايش الخدمات الي تقدمها مغاسل؟</p>
                  </label>
                  <div class="tab-content">
                    غسيل وكوي، كوي فقط، الغسيل الجاف
                  </div>
                </div>
                <div class="tab">
                  <input type="checkbox" id="chck2" />
                  <label class="tab-label" for="chck2">
                    <p class="anim">ايش نوع السكن الي توفرون له خدمات مغاسل؟</p>
                  </label>
                  <div class="tab-content">
                    نقدم خدماتنا للجميع ولكن شنطة مغاسل خاصة فقط لأصحاب الشقق
                  </div>
                </div>
                <div class="tab">
                  <input type="checkbox" id="chck3" />
                  <label class="tab-label" for="chck3">
                    <p class="anim">
                      هل احتاج اكون في البيت وقت التسليم والاستلام؟
                    </p>
                  </label>
                  <div class="tab-content">
                    أكيد لا. كل الي عليك انك تعلق شنطة مغاسل على الباب وتطلع
                    تستمتع بيومك
                  </div>
                </div>
                <div class="tab">
                  <input type="checkbox" id="chck4" />
                  <label class="tab-label" for="chck4">
                    <p class="anim">
                      هل احتاج احسب عدد قطع الملابس قبل احطها في شنطة مغاسل؟
                    </p>
                  </label>
                  <div class="tab-content">لا. احنا نحسبها عنك</div>
                </div>
                <div class="tab">
                  <input type="checkbox" id="chck5" />
                  <label class="tab-label" for="chck5">
                    <p class="anim">متى ترجعون الملابس المغسولة؟</p>
                  </label>
                  <div class="tab-content">خلال ٢٤ ساعة وملابسك على بابك</div>
                </div>
                <div class="tab">
                  <input type="checkbox" id="chck6" />
                  <label class="tab-label" for="chck6">
                    <p class="anim">وين تغسلون ملابسنا؟</p>
                  </label>
                  <div class="tab-content">
                    عندنا شراكة استراتيجية مع مزودين خدمات لتنظيف ملابسكم بأفضل
                    جودة وأفضل مواد
                  </div>
                </div>
                <div class="tab">
                  <input type="checkbox" id="chck7" />
                  <label class="tab-label" for="chck7">
                    <p class="anim">كيف اعرف اسعاركم للغسيل؟</p>
                  </label>
                  <div class="tab-content">
                    باختصار اسعارنا مثل اسعار السوق. مع ذلك تقدر تشوف الاسعار من
                    خلال صفحة الاسعار في التطبيق.
                  </div>
                </div>
                <div class="tab">
                  <input type="checkbox" id="chck8" />
                  <label class="tab-label" for="chck8">
                    <p class="anim">كيف طريقة الدفع؟</p>
                  </label>
                  <div class="tab-content">
                    تقدر تدفع من خلال مدى او البطاقات الائتمانية من خلال التطبيق
                  </div>
                </div>

                <div class="tab">
                  <input type="checkbox" id="chck10" />
                  <label class="tab-label" for="chck10">
                    <p class="anim">كم سعر شنطة مغاسل؟</p>
                  </label>
                  <div class="tab-content">
                    سعر الشنطة حالياً ب ٣٩ ريال فقط تدفع مرة واحدة مدى الحياة.
                    وراح يتم احتساب سعر الشنطة مع فاتورة أول طلب لك.
                  </div>
                </div>
                <div class="tab">
                  <input type="checkbox" id="chck11" />
                  <label class="tab-label" for="chck11">
                    <p class="anim">كم تكلفة التوصيل في تطبيق مغاسل؟</p>
                  </label>
                  <div class="tab-content">
                    سعر التوصيل ب ٩ ريال ومجاني للطلبات ب ٩٩ ريال وفوق
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="show3" class="mt-5 col-lg-8 col-md-12">
        <div class="colacc container droid-arabic-kufi">
          <div class="row" lang="ar" dir="rtl">
            <div class="col">
              <br />
              <br />
              <br />
              <div class="tabs">
                <div class="tab">
                  <input type="checkbox" id="chck1" />
                  <!-- style="visibility: visible; animation-duration: 1s; animation-delay: 0.1s; animation-name: slideInLeft;" -->
                  <label class="tab-label" for="chck1">
                    <p class="anim">ايش هو برنامج شارك واربح؟</p>
                  </label>
                  <div class="tab-content">
                    راح تحصل على ٢٥ ريال في محفظتك اذا عملت مشاركة للتطبيق مع
                    اهلك واصحابك بعد طلبهم الأول. وايضاً الشخص الي شاركته
                    التطبيق راح يحصل على ٢٥ ريال في محفظته تستخدم في الطلب
                    الثاني
                  </div>
                </div>
                <div class="tab">
                  <input type="checkbox" id="chck2" />
                  <label class="tab-label" for="chck2">
                    <p class="anim">ايش اسوي لو عندي شكوى؟</p>
                  </label>
                  <div class="tab-content">
                    احنا دائماً موجودين لخدمتك. تقدر تتواصل معانا خلال ٤٨ ساعة
                    من بعد استلام طلبك من خلال قنوات التواصل. وراح نكون سعيدين
                    بخدمتك.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="droid-arabic-kufi wow zoomIn">
  <div class="container">
    <div class="page-title-content">
      <h2 class="col2">الأحكام والشروط</h2>
    </div>
  </div>
</div>

<div lang="ar" dir="rtl" class="about-area droid-arabic-kufi ptb-100">
  <div class="container">
    <div class="justify-content-center row">
        <p [innerHTML]="data.text" style="width: 100%;"></p>
    </div>
  </div>
</div>

import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { SiteService } from 'src/app/services/site.service';

const style1 = style({
  opacity: 1,
  transform: "translateX(0)"
})

const style2 = style({
  opacity: 0,
  transform: "translateX(-100%)"
})
const style3 = style({
  opacity: 0,
  transform: "translateX(100%)"
})
@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss'],

  animations: [
    trigger('foobar', [
      state('show', style1),
      state('hide', style2),
      transition('show => hide', animate('700ms ease-out')),
      transition('hide => show', animate('700ms ease-in'))
    ]),
    trigger('foobar2', [
      state('show', style1),
      state('hide', style3),
      transition('show => hide', animate('700ms ease-out')),
      transition('hide => show', animate('700ms ease-in'))
    ])
  ]
})

export class ServiceComponent implements OnInit {
  state = 'hide'
  data: any;

  constructor(private service: SiteService, public el: ElementRef) { }

  ngOnInit(): void {
    this.service.get().subscribe(
      (res: any) => {
        this.data = res.data
      }
    )
  }
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const componentPosition = this.el.nativeElement.offsetTop
    const scrollPosition = window.scrollY
    if (scrollPosition >= componentPosition -(componentPosition*90/100)) {
      this.state = 'show'
     // console.log(componentPosition);
      
    } else {
      this.state = 'hide'
    }

  }

/* 
  @HostListener('window:scroll', ['$event'])
  checkScroll(e:any) {

    let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    let max = document.documentElement.scrollHeight;
     if(pos == max )   {
     console.log();
     
     }
  } */
}
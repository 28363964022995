
  <div class="bg container">
    
  <div class="pos row">
    <div class="col-md-6 col3 wow zoomIn">
        
        
         

            <img  *ngIf="data.headerImage" [src]="server + data?.headerImage?.public_url" width="60%">
            <img  *ngIf="!data.headerImage" [src]="'assets/img/Illustration5.svg'" width="60%">
    
    </div>
    <div class="col-md-6 droid-arabic-kufi main-banner-content">
        
        <div lang="ar" dir="rtl" class="app-download-content wow zoomIn">
            <h1 lang="ar" dir="rtl" class="sub-title">  {{data.headerTitle}}
            </h1>
            <h2 lang="ar" dir="rtl" class="col2"> {{data.headerSubTitle}}  
            </h2>
            <p lang="ar" dir="rtl" class="text">
              {{data.headerDescription}}

            </p>
            <div class="container">
                <div class="row">
                  <div class="col position">
                    <a href="#" class="playstore-btn" target="_blank">
                        <img src="assets/img/app-store3.svg"  alt="image">
                       
                    </a>
                  </div>
                  <div class="col position">
                    <a href="#" class="applestore-btn" target="_blank">
                        <img src="assets/img/google-play3.svg" alt="image">
                    </a>                                              </div>
                </div>
            </div>
            
        </div>    
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { SiteService } from 'src/app/services/site.service';

@Component({
  selector: 'app-term-condition',
  templateUrl: './term-condition.component.html',
  styleUrls: ['./term-condition.component.scss']
})
export class TermConditionComponent implements OnInit {
  data: any;

  constructor(private siteService: SiteService) { }

  ngOnInit(): void {
    this.siteService.getTerms().subscribe(
      (res: any) => this.data = res.data
    )
  }

}


<div class="droid-arabic-kufi">
    <div class="container">
        <div class="page-title-content">
            <h1 class="mb-4 col2 wow zoomIn">
              {{data.aboutUsTitle}} 
            </h1>

        </div>
    </div>
</div>


<div class="container">
  <div class="row">
    <div class="col-sm-6" >  <div class="anim col">
      <img  *ngIf="data.aboutUsImage" [src]="server + data?.aboutUsImage?.public_url" width="60%">
      <img  *ngIf="!data.aboutUsImage" [src]="'assets/img/Illustration6.svg'" width="60%">
    </div></div>
    <div class="col-sm-6"><div class="team-area pt-100 pb-75 droid-arabic-kufi">
      <div lang="ar" dir="rtl" class="container">
          <div class="container">
              <div class="row">
            
                  <p class="col text"> 
                    {{data.aboutUsDescription}} 
                  </p>

            
              
              </div>
              
            </div>
      </div>
  </div></div>
  </div>

</div>


<div lang="ar" dir="ltr" class="container droid-arabic-kufi pos2" >
    <div class="section-title">
        <h2 class="wow zoomIn">طريقة العمل
        </h2>
        <p class="text wow zoomIn">
            {{data.howItWorksDescription}}
        </p>
    </div>
    

    
    
    
    <div class="bg9 container">
    
        <div class="bg faq row">
    
            <div class="bg2 col">
                <div>
                    <div class="bg3 wow zoomIn">
                        <img class="" src="assets/img/box.png" width="50" height="50">
                    </div>
                    <a type="submit" class="pos7 wow zoomIn" ><span class="text">نرجعها</span></a>
                </div>
            </div>
    
            <div class="bg2 col">
                <div>
                    <div class="wow zoomIn">
                        <img class="" src="assets/img/washing-machine.png" width="50" height="50">
                    </div>
                    <a  type="submit" class="pos7 wow zoomIn"><span class="text"> نغسّلها</span></a>
                </div>
            </div>
    
    
            <div class="bg2 col">
                <div>
                    <div class="wow zoomIn">
                        <img class="" src="assets/img/delivery-man.png" width="50" height="50">
                    </div>
                    <a  type="submit" class="pos7 wow zoomIn"><span class="text">نستلمها</span></a>
                </div>
            </div>


            <div class="bg2 col">
                <div>
                    <div class="wow zoomIn">
                        <img class="" src="assets/img/sport-wear.png" width="50" height="50">
                    </div>
                    <a  type="submit" class="pos7 wow zoomIn"><span class="text">أطلب استلام الملابس
                    </span></a>
                </div>
            </div>
    
           
        </div>
    </div>
    
    



</div>
<!-- <div class="container droid-arabic-kufi pos6 wow zoomIn">
<div class="bg row">
    <div class="col"> <div >
        <div class="single-features-item">
            
            <div class="icon">
                <img class="pos" src="assets/img/box.png" width="40" height="40">
            </div>
          
            <h3 class="">نرجعها </h3>

        </div>
    </div></div>
    <div class="col"> <div >
        <div class="single-features-item">
            <div class="bg2 icon">
                <img class="pos" src="assets/img/washing-machine.png" width="40" height="40">
            </div>
            <h3>نغسّلها </h3>
        </div>
    </div></div>
    <div class="col">    <div >
        <div class="single-features-item">
            <div class="bg4 icon">
                <img class="pos" src="assets/img/delivery-man.png" width="40" height="40">
            </div>
            <h3>نستلمها </h3>
        </div>
    </div></div>
    <div class="col"> <div >
        <div class="single-features-item">
            <div class="bg3 icon">
                <img class="pos" src="assets/img/sport-wear.png" width="40" height="40">
            </div>
            <h3  class="text1">أطلب استلام الملابس
            </h3>
        </div>
    </div></div>
  </div>
</div>

 -->